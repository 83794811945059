import { EventEmitter, Injectable } from '@angular/core';

export interface BreadcrumbSection {
  name: string;
  link: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  navchange: EventEmitter<string> = new EventEmitter();
  breadcrumbsChange: EventEmitter<BreadcrumbSection[]> = new EventEmitter();

  private _section: string = 'Carousel';
  public get section(): string {
    return this._section;
  }
  public set section(v: string) {
    this._section = v;
    this.navchange.emit(this._section);
  }

  private _breadcrumbs: BreadcrumbSection[] = [];
  public get breadcrumbs(): BreadcrumbSection[] {
    return this._breadcrumbs;
  }
  public set breadcrumbs(v: BreadcrumbSection[]) {
    this._breadcrumbs = v;
    this.breadcrumbsChange.emit(this._breadcrumbs);
  }

  constructor() { }
}
