import { Injectable } from '@angular/core';
import { AboutUs, AboutUsListResponse } from '../models/about-us';
import { CarouselVideosListResponse } from '../models/carousel-video';
import { SuccessCasesListResponse } from '../models/success-cases';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private api: ApiService) { }

  public async getCarouselVideos() {
    const res = await this.api.get<CarouselVideosListResponse>('VideosCarousel');
    if (res.code === 200) {
      return res.videosCarouselList.filter(v => v.enabled);
    } else {
      throw new Error(res.message);
    }
  }

  public async getAboutUs() {
    const res = await this.api.get<AboutUsListResponse>('weare');
    if (res.code === 200) {
      const list = res.listWeAre;
      const aboutUs: AboutUs = {
        body: list.find(l => l.reference === 'Body')?.text || '',
        title: list.find(l => l.reference === 'Title')?.text || '',
        body1: list.find(l => l.reference === 'Body1')?.text || '',
        body2: list.find(l => l.reference === 'Body2')?.text || '',
        body3: list.find(l => l.reference === 'Body3')?.text || '',
        body4: list.find(l => l.reference === 'Body4')?.text || '',
        sub1: list.find(l => l.reference === 'Sub1')?.text || '',
        sub2: list.find(l => l.reference === 'Sub2')?.text || '',
        sub3: list.find(l => l.reference === 'Sub3')?.text || '',
        sub4: list.find(l => l.reference === 'Sub4')?.text || '',
      };
      return aboutUs;
    } else {
      throw new Error(res.message);
    }
  }

  public async getSuccessCases() {
    const res = await this.api.get<SuccessCasesListResponse>('successCases');
    if (res.code === 200) {
      return res.successCaseDTOs.filter(sc => sc.enabled);
    } else {
      throw new Error(res.message);
    }
  }
}
