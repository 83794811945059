import { Injectable } from '@angular/core';
import { IndustriesListResponse, Industry, IndustryResponse } from '../models/industries';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {

  constructor(private api: ApiService) { }

  public async getList() {
    const res = await this.api.get<IndustriesListResponse>('industries');
    if (res.code === 200) {
      return res.industries;
    } else {
      throw new Error(res.message);
    }
  }

  public async getById(id: number) {
    try {
      const res = await this.api.get<IndustryResponse>('industries/' + id);
      if (res.code === 200) {
        return res.industry;
      } else {
        return {} as Industry;
      }
    } catch (error) {
      return {} as Industry;
    }
  }
}
