import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseurl = environment.apiURL;

  constructor(private http: HttpClient) { }

  public async get<T>(endpoint: string) {
    const url = this.baseurl + endpoint;
    return this.http.get<T>(url).toPromise();
  }

  public async post<T>(endpoint: string, body: any) {
    const url = this.baseurl + endpoint;
    return this.http.post<T>(url, body).toPromise();
  }

  public async postForm<T>(endpoint: string, formData: FormData) {
    const url = this.baseurl + endpoint;
    return this.http.post<T>(url, formData).toPromise();
  }
}
