import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ChatForm, ChatMessage, Country, IndustryVendor } from '../models/chat';
import { ChatService } from '../services/chat.service';
import { IndustryService } from '../services/industry.service';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { async } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  public countries: Country[] = [];
  private industryId: number = 0;
  public industryName: string = '';
  private token: string = '';
  public vendor: IndustryVendor = { accountId: 0, accountName: 'Conectando...', online: false };
  public expired: boolean = false;
  public chatOpen: boolean = false;
  public messages: ChatMessage[] = [];
  public formOpen: number = 0;
  public loadingChat: boolean = true;
  public hubConnection:HubConnection|any;
  private timeout: any;
  private vendorTimeout: any;
  private urlSocket=environment.socket
  constructor(
    private chatService: ChatService,
    private industryService: IndustryService,
    private chRef: ChangeDetectorRef,
    router: Router
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlParts = event.url.split('/');
        const industryIndex = urlParts.indexOf('industry');
        const industryId = +urlParts[industryIndex + 1];

        if (industryId !== this.industryId) {
          this.industryId = industryId;
          if (this.timeout) {
            clearInterval(this.timeout);
          }
          if (this.vendorTimeout) {
            clearInterval(this.vendorTimeout);
          }
          const token = this.chatService.getLocalToken(industryId)?.token as string;
          // const token = 'CLIENT-TOKEN4'; // TEST
          if (token) {
            this.token = token;
            this.loadVendor(token);
            this.loadMessages(token, true);
          } else {
            this.getNewToken();
            this.getNewVendor(industryId);
          }
          this.loadIndustry(industryId);
        }
      }
    });
  }

  ngOnInit(): void {
     //socket
     let builder= new HubConnectionBuilder();
     this.hubConnection=builder.withUrl(this.urlSocket).build();
     this.hubConnection.on("UserOnline",(mensaje:any)=>{
       setTimeout(() => {
         this.loadVendorStatusOnline()
        }, 2000);
        
        console.log(mensaje) })
        this.hubConnection.on("UserOffline",(mensaje:any)=>{
          setTimeout(() => {
            this.loadVendorStatusOnline()
          }, 2000);
     
      console.log(mensaje)})
      this.hubConnection.on("Message",(mensaje:any)=>{
        console.log(mensaje)
        setTimeout( () => {
          this.loadMessages(this.token,true)
        }, 2000);
      })
     this.hubConnection.start();
    this.loadCountries();
  }

  public openCloseChat() {
    if (!this.vendor.accountId) {
      return;
    }
    this.chatOpen = !this.chatOpen;
    if (this.chatOpen && !this.vendor.online && !this.messages.length) {
      this.formOpen = 3;
    } else {
      this.formOpen = 0;
    }
  }

  private async loadCountries() {
    this.countries = await this.chatService.getCountries();
  }

  private async loadIndustry(id: number) {
    const industry = await this.industryService.getById(id);
    this.industryName = industry.name;
  }

  private async getNewToken() {
    this.token = await this.chatService.getOnlineToken();
  }

  private async getNewVendor(industryId: number) {
    this.vendor = await this.chatService.getNewVendor(industryId);
    this.loadingChat = false;
   // this.loadVendorStatus();
  }

  private async loadVendor(token: string) {
    this.vendor = await this.chatService.getCurrentVendor(token);
   // this.loadVendorStatus();
  }

  private async loadMessages(token: string, first?: boolean) {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
    const messagesRes = await this.chatService.getMessages(token);
    this.expired = !messagesRes.validChatToken;
    this.messages = messagesRes.messages;
    this.loadingChat = false;
    this.chRef.detectChanges();
    const div = document.getElementById('messagesContainer') as HTMLDivElement;
    if (first || div.scrollHeight - 45 <= div.scrollTop + div.offsetHeight) { // if scrolled to bottom
      div.scrollTo({ top: div.scrollHeight, behavior: 'smooth' });
    }
    // this.timeout = setTimeout(() => {
    //   this.loadMessages(token);
    // }, 5000);
  }

  // private async loadVendorStatus() {
  //   if (this.vendorTimeout) {
  //     clearInterval(this.vendorTimeout);
  //   }
  //   try {
  //     this.vendor.online = await this.chatService.getVendorStatus(this.vendor.accountId);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   this.vendorTimeout = setTimeout(() => {
  //     this.loadVendorStatus();
  //   }, 5000);
  // }
  private async loadVendorStatusOnline() {
    // if (this.vendorTimeout) {
    //   clearInterval(this.vendorTimeout);
    // }
    try {
      this.vendor.online = await this.chatService.getVendorStatus(this.vendor.accountId);
    } catch (error) {
      console.log(error);
    }
    // this.vendorTimeout = setTimeout(() => {
    //   this.loadVendorStatus();
    // }, 5000);
  }

  public openForm(message: ChatMessage) {
    if (message.form1Completed) {
      this.formOpen = 1;
    } else if (message.form2Completed) {
      this.formOpen = 2;
    }
  }

  public async sendMessage(textarea: HTMLTextAreaElement) {
    const message = textarea.value.trim();
    textarea.value = '';
    if (message) {
      const first = !Boolean(this.messages.length); // Detecto si es el primer mensaje.
      const chatMessage: ChatMessage = { message, clientToken: this.token, sending: true };
      this.messages.push(chatMessage);
      this.chRef.detectChanges();
      const div = document.getElementById('messagesContainer') as HTMLDivElement;
      div.scrollTo({ top: div.scrollHeight, behavior: 'smooth' });
      if (first) {
        chatMessage.accountId = this.vendor.accountId;
        chatMessage.industryId = this.industryId;
      }
      console.log(chatMessage)
      await this.chatService.sendMessage(chatMessage);
      console.log('se envio correctamente')
      const { token, industryId } = this;
      if (first) { // Si es el primer mensaje y es exitoso, guardo el token
        this.chatService.setLocalToken({ token, industryId });
      }
      this.loadMessages(token);
      this.chatService.sendSocket();
    }
  }

  public onlyNumbers(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, '');
  }

  public async submitForm(form: HTMLFormElement) {
    const name: string = form.clientName.value.trim();
    const legalName: string = form.legalName.value.trim();
    const email: string = form.mail.value.trim();
    const phoneNumber: string = form.phone.value.trim();
    const occupation: string = form.occupation.value.trim();
    let cuit: string = '';
    let address: string = '';
    let countryId: number = 0;
    let message: string = '';

    if (this.formOpen !== 2) {
      cuit = form.taxId.value.trim();
      address = form.address.value.trim();
      countryId = +form.country.value;
    }

    if (this.formOpen === 3) {
      message = form.message.value.trim();
    }

    let msg: string = '';
    if (!name || !email || !legalName) {
      msg += 'Por favor complete los campos obligatorios.\n';
    } else if (!this.validateEmail(email)) {
      msg += 'El email ingresado no es válido\n';
    }
    if (cuit && cuit.length !== 11) {
      msg += 'El CUIT debe contener 11 caracteres'
    }

    if (msg) {
      alert(msg);
      return;
    }
    const clientToken = this.token;

    const chatForm: ChatForm = {
      name, cuit, legalName, address, phoneNumber, countryId, email, occupation,
    };
    const chatMessageForm: ChatMessage = {
      clientToken,
      message: 'Formulario completado',
      sending: true
    }
    if (this.formOpen !== 2) {
      chatMessageForm.form1 = chatForm;
      chatMessageForm.form1Completed = true;
    } else { // El Form 3 se trata como un form 1 + un mensaje
      chatMessageForm.form2 = chatForm;
      chatMessageForm.form2Completed = true;
    }
    if (this.formOpen === 3) { // Chat desplegado con vendedor no disponible, es el primer mensaje siempre
      chatMessageForm.accountId = this.vendor.accountId;
      chatMessageForm.industryId = this.industryId;
    }

    this.messages.push(chatMessageForm);
    if (this.formOpen === 3) {
      this.formOpen = 0;
      this.messages.push({ message, sending: true });
      await this.chatService.sendMessage(chatMessageForm);
      // Mando un mensaje extra con lo completado por el usuario en el campo de mensaje
      await this.chatService.sendMessage({ clientToken, message });
      const { token, industryId } = this;
      this.chatService.setLocalToken({ token, industryId });
    } else {
      this.formOpen = 0;
      await this.chatService.sendMessage(chatMessageForm);
    }
    form.reset();
    this.chatService.sendSocket();
  }



  private validateEmail(email: string): boolean {
    const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return mailRegex.test(email);
  }

  public async newChat() {
    clearInterval(this.timeout);
    clearInterval(this.vendorTimeout);
    this.loadingChat = true;
    this.expired = false;
    this.messages = [];
    this.getNewToken();
    this.vendor = { online: false, accountName: 'Asignando vendedor...', accountId: 0 };
    await this.getNewVendor(this.industryId);
    if (!this.vendor.online) {
      this.formOpen = 3;
    }
  }

}
