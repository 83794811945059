// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiURL: 'https://localhost:44308/api/',
  //socket:'https://localhost:44324/socket',
  socket:'https://ws.wearelomo.com/socket',
  //apiSocket:'https://localhost:44324/api/',
  apiSocket:'https://ws.wearelomo.com/api/',

   // apiURL: 'https://api.fdscontrol.com/api/'

   apiURL: 'https://fdscontroldemodev.wearelomo.com/services/api/'
    
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
