<router-outlet></router-outlet>
<div class="chat-container" [ngClass]="{'form-container': formOpen && chatOpen}">
  <div class="chat-header" (click)="openCloseChat()">
    <p *ngIf="!chatOpen && vendor.online" class="collpased-text">¿En qué te puedo ayudar?</p>
    <div>
      <img src="/assets/img/bubbles-circle.png" alt="">
      <div class="vendor-section">
        <h4 class="vendor-name">{{vendor.accountName}}</h4>
        <div class="vendor-status">
          <div class="status-color" [style.backgroundColor]="vendor.online ? '#00E417' : '#F60000'"></div>
          <span class="status-text">
            {{ vendor.accountId == 0 ? 'Conectando con vendedor' :
            vendor.online ? 'Disponible para conversar' : 'No disponible, déjame tu mensaje'}}
          </span>
        </div>
      </div>
      <span class="material-icons">close</span>
    </div>
  </div>
  <div class="chat-body" [ngClass]="{'expanded': chatOpen && !formOpen}">
    <div class="messages-container" id="messagesContainer" [ngClass]="{'disabled': expired}">
      <p *ngFor="let message of messages" class="chat-message"
        [ngClass]="{'self': !message.sendByUser, 'sending': message.sending}">
        <ng-container *ngIf="!message.form1Completed && !message.form2Completed">
          {{ message.message }}
        </ng-container>
        <button *ngIf="message.sendByUser && (message.form1Completed || message.form2Completed)"
          (click)="openForm(message)" [disabled]="expired">
          Completar formulario
        </button>
        <strong *ngIf="!message.sendByUser && (message.form1Completed || message.form2Completed)">
          Formulario completado
        </strong>
      </p>
    </div>
    <div class="chat-input">
      <p *ngIf="loadingChat">Cargando...</p>
      <ng-container *ngIf="!expired && !loadingChat">
        <textarea #tArea placeholder="Escribe un mensaje..." (keyup.enter)="sendMessage(tArea)"></textarea>
        <button class="material-icons" (click)="sendMessage(tArea)">send</button>
      </ng-container>
      <div class="new-chat" *ngIf="expired">
        <p>Este chat ha finalizado</p>
        <button (click)="newChat()">Iniciar nuevo chat</button>
      </div>
    </div>
  </div>
  <form class="chat-form" #form [ngClass]="{'expanded': chatOpen && formOpen}">
    <div class="chat-form-fields-container">
      <div class="chat-form-field">
        <span>Nombre *</span>
        <input name="clientName" placeholder="Ingresa aquí tu nombre" required>
      </div>
      <div class="chat-form-field">
        <span>Razón Social *</span>
        <input name="legalName" placeholder="Ingresa aquí la Razón Social" required>
      </div>
      <div class="chat-form-field" *ngIf="formOpen != 2">
        <span>País</span>
        <select name="country" placeholder="Selecciona el país de la empresa">
          <option *ngFor="let country of countries" [value]="country.countryID">
            {{ country.description }}
          </option>
        </select>
      </div>
      <div class="chat-form-field">
        <span>Email *</span>
        <input name="mail" placeholder="Ingresa aquí tu email" required>
      </div>
      <div class="chat-form-field" *ngIf="formOpen != 2">
        <span>Domicilio</span>
        <input name="address" placeholder="Ingresa aquí el Domicilio">
      </div>
      <div class="chat-form-field" *ngIf="formOpen != 2">
        <span>Número de identificación tributaria</span>
        <input name="taxId" maxlength="11" placeholder="Ingresa aquí el número de identificación tributaria" (keyup)="onlyNumbers($event)">
      </div>
      <div class="chat-form-field">
        <span>Teléfono</span>
        <input name="phone" placeholder="Ingresa aquí tu teléfono">
      </div>
      <div class="chat-form-field">
        <span>Cargo en la empresa</span>
        <input name="occupation" placeholder="Ingresa aquí tu cargo en la Empresa">
      </div>
      <ng-container *ngIf="formOpen == 3">
        <div class="chat-form-field">
          <span>Industria a consultar</span>
          <input type="text" disabled [value]="industryName">
        </div>
        <div class="chat-form-field">
          <span>Mensaje</span>
          <textarea name="message" placeholder="Ingresa aquí tu mensaje"></textarea>
        </div>
      </ng-container>
    </div>
    <button class="submit-form" (click)="submitForm(form)">Enviar</button>
  </form>
</div>
