<hr>
<div class="quotation-message">
  <div class="quotation-message-sender" [ngClass]="{'seller': message.accountName}">
    <div class="avatar-container">
      <img src="/assets/img/icons/perm_identity.svg">
    </div>
    <div class="sender-info">
      <h3 *ngIf="message.contactName">{{message.companyName}}, {{message.contactName}}</h3>
      <h3 *ngIf="message.accountName">FDS Control, {{message.accountName}}</h3>
      <span>Publicado el {{message.createdDateTime | date:"dd 'de' MMMM 'a las' h:mm:ss a":"GMT-6"}}</span>
    </div>
  </div>
  <p class="message-content">{{message.message}}</p>
  <div class="message-attachments-container" *ngIf="message.files?.length">
    <a class="message-attachment" *ngFor="let file of message.files" [href]="file.fileUrl" target="_blank">
      <img src="/assets/img/icons/download-attachment.svg">
      <span>{{file.fileName}}</span>
    </a>
  </div>
</div>
