<div class="main-container" *ngIf="quotation" [ngClass]="{'export': exporting}">
  <div class="export-cover" *ngIf="exporting"></div>
  <section class="cover" [ngStyle]="{'background-image': 'url(' + quotation.industry.industryImageUrl + ')'}"
    id="quotationCover" [ngClass]="{'no-bg': !quotation.industry.industryImageUrl}">
    <div class="logo-industry">
      <img src="/assets/img/fds-logo-1.png" alt="Logo FDS">
      <div class="div-separator"></div>
      <h2>{{quotation.industry.industryName}}</h2>
    </div>
    <div class="quotation-info">
      <h1>Presupuesto</h1>
      <div>
        <span>Número de Presupuesto</span>
        <span>{{quotation.quotation.quotationID}}</span>
        <span>Fecha de Presupuesto</span>
        <span>{{quotation.quotation.creationDate | date: 'dd-MM-yyyy'}}</span>
      </div>
    </div>
  </section>
  <div class="quotation-body">
    <div class="floating-container">
      <div class="floating">
        <div class="quotation-number">
          <span>Presupuesto</span><span>N°{{quotation.quotation.quotationID}}</span>
        </div>
        <div class="quotation-date">
          <span>Fecha de presupuesto</span><span>{{quotation.quotation.creationDate | date: 'dd-MM-yyyy'}}</span>
        </div>
        <div class="quotation-total">
          <span>Total</span><span>US$ {{quotation.quotation.total | number: '1.2-2'}}</span>
        </div>
        <a href="javascript:void(0)" (click)="exportToPDF()">
          <img src="/assets/img/icons/export.svg" alt="">
          Ver como PDF
        </a>
        <div class="desktop">
          <p>Accesos rápidos</p>
          <a href="javascript:void(0)" (click)="scrollToElement(items)">Ítems Cotizados</a>
          <a href="javascript:void(0)" (click)="scrollToElement(conditions)">Condiciones de Venta</a>
          <a href="javascript:void(0)" (click)="scrollToElement(contact)">Datos de Contacto</a>
          <a href="javascript:void(0)" (click)="scrollToElement(messages)">Historial de Mensajes</a>
        </div>
        <div class="desktop">
          <p>Atendido por</p>
          <h3>{{quotation.seller.firstName}} {{quotation.seller.lastName}}</h3>
          <div class="sticky-seller-contact">
            <span>{{quotation.seller.phoneNumber || '-'}}</span>
            <span>{{quotation.seller.email}}</span>
          </div>
        </div>
      </div>
    </div>
    <section class="portfolio" *ngIf="quotation.industry.products && quotation.industry.products.length" id="portfolio">
      <h2>Portafolio de productos</h2>
      <div class="products-container">
        <div class="product" *ngFor="let product of quotation.industry.products">
          <div class="product-img" [ngStyle]="{'background-image': 'url(' + product.imageUrl + ')'}"></div>
          <h3>{{product.productName}}</h3>
          <p>{{product.description}}</p>
        </div>
      </div>
      <div class="footer"></div>
    </section>
    <section class="summary">
      <div class="summary-header-pre" id="summaryHeaderPre">
        <h2>Presupuesto</h2>
        <img class="desktop" src="/assets/img/fds-logo-1.png" alt="Logo Fluids Control">
      </div>
      <div class="summary-header" id="summaryHeader">
        <div class="summary-header-item">
          <div>
            <p>Señores:</p>
            <h3>{{quotation.quotation.clientLegalName}}</h3>
          </div>
          <p>{{quotation.contact.address}}</p>
          <div class="summary-header-contact">
            <span>Teléfono: {{quotation.contact.clientPhoneNumber || '-'}}</span>
          </div>
        </div>
        <div class="summary-header-item summary-data">
          <span>Número de Presupuesto</span>
          <strong>{{quotation.quotation.quotationID}}</strong>
          <span>Fecha de Presupuesto</span>
          <strong>{{quotation.quotation.creationDate | date: 'dd-MM-yyyy'}}</strong>
          <span>Atendido por</span>
          <strong>{{quotation.seller.firstName}} {{quotation.seller.lastName}}</strong>
          <span>Teléfono</span>
          <strong>{{quotation.seller.phoneNumber || '-'}}</strong>
          <span>Email</span>
          <strong>{{quotation.seller.email}}</strong>
        </div>
        <div class="summary-header-item">
          <div>
            <p>Atención:</p>
            <h3>{{quotation.contact.name}}</h3>
          </div>
          <p>Teléfono: {{quotation.contact.phone || '-'}}</p>
          <div class="summary-header-contact">
            <span>Email: {{quotation.contact.email}}</span>
          </div>
        </div>
        <div class="summary-header-item summary-payment">
          <p>Condiciones de pago:</p>
          <p>{{quotation.quotation.paymentConditions}}</p>
        </div>
      </div>
      <div class="summary-items" #items>
        <p id="preTableMessage">De acuerdo a su solicitud, tenemos el agrado de cotizarle lo siguiente.</p>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>Ítem</th>
                <th>Número de parte</th>
                <th>Cantidad</th>
                <th>Plazo de entrega</th>
                <th>Precio unitario</th>
                <th>Precio total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of quotation.quotationItems; let i = index">
                <td>{{i+1}}.-</td>
                <td>{{item.partNumber}}</td>
                <td>{{item.quantity}}</td>
                <td>{{item.deliveryTerm}}</td>
                <td>US$ {{item.unitPrice | number: '1.2-2'}}</td>
                <td>US$ {{item.partialTotal | number: '1.2-2'}}</td>
              </tr>
              <!-- <tr class="desktop">
                <td colspan="4"></td>
                <td>Subtotal</td>
                <td>US$ {{quotation.quotation.subtotal | number: '1.2-2'}}</td>
              </tr>
              <tr class="desktop">
                <td colspan="4"></td>
                <td>Descuento</td>
                <td>US$ {{quotation.quotation.discount | number: '1.2-2'}}</td>
              </tr> -->
              <tr class="desktop">
                <td colspan="4"></td>
                <td>Total</td>
                <td>US$ {{quotation.quotation.total | number: '1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <p id="postTableMessage">Los precios cotizados no incluyen IVA.</p> -->
      </div>
      <div class="items-description-container">
        <div class="item-description" *ngFor="let item of quotation.quotationItems; let i = index">
          <h3>Descripción del item {{i + 1}}:</h3>
          <p>{{item.description}}</p>
        </div>
      </div>
      <div id="sellConditions" #conditions>
        <!-- <h2 #conditions>Condiciones de Venta:</h2> -->
        <!-- <div class="sell-conditions" [innerHTML]="quotation.quotation.country.sellConditions">
        </div> -->
        <app-rich-text-editor [value]="quotation.quotation.country.sellConditions" [sellConditions]="true"
          [disabled]="true"></app-rich-text-editor>
        <p class="signature" id="signature">
          Atentamente,<br><br>
          {{quotation.seller.firstName}} {{quotation.seller.lastName}}<br>
          {{quotation.seller.email}}
        </p>
      </div>
      <div class="summary-footer" #contact id="summaryFooter">
        <a class="summary-footer-item" href="https://goo.gl/maps/xpqp7pB2xpLnMEkq6" target="_blank">
          <img src="/assets/img/icons/address.svg" alt="Dirección">
          Lopez y Planes 5558, Billinghurst, General San Martín, Bs. As. Argentina
        </a>
        <a class="summary-footer-item" href="tel:+541173977886">
          <img src="/assets/img/icons/call.svg" alt="Teléfono">
          Tel: +54 11 7397-7886
        </a>
        <a class="summary-footer-item" href="mailto:institucional@fdscontrol.com">
          <img src="/assets/img/icons/email.svg" alt="E-mail">
          institucional@fdscontrol.com
        </a>
        <a class="summary-footer-item desktop" href="https://fdscontrol.com" target="_blank">
          <img src="/assets/img/icons/web.svg" alt="Sitio">
          www.fdscontrol.com
        </a>
      </div>
    </section>
    <section class="messages" #messages>
      <h2>Historial de mensajes</h2>
      <div class="messages-counter">
        <img src="/assets/img/icons/forum.svg" alt="">
        <small>{{quotationMessages.length}} Comentario{{quotationMessages.length == 1 ? '' : 's'}}</small>
      </div>
      <div class="new-message">
        <textarea placeholder="Escribe un Mensaje aquí..." #textarea></textarea>
        <div class="message-actions">
          <button (click)="sendMessage(textarea)">Enviar</button>
          <label>
            <img src="/assets/img/icons/attachment.svg" alt="Adjuntar archivo">
            <input type="file" (change)="selectFile($event.target)" multiple="multiple">
          </label>
          <div class="attachment" *ngFor="let file of files">
            {{file.name}}
            <span class="material-icons" (click)="removeFile(file)">close</span>
          </div>
        </div>
      </div>
      <div class="messages-container" *ngIf="quotationMessages.length">
        <div *ngFor="let message of quotationMessages" class="quotation-message-outer">
          <app-quotation-message [message]="message"></app-quotation-message>
        </div>
      </div>
    </section>
  </div>
</div>
