import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { HomeComponent } from './home/home.component';
import { IndustryComponent } from './industry/industry.component';
import { ProductComponent } from './product/product.component';
import { ChatComponent } from './chat/chat.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DigitalQuotationComponent } from './digital-quotation/digital-quotation.component';
import { QuotationMessageComponent } from './components/quotation-message/quotation-message.component';

import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { RichTextEditorModule } from './components/rich-text-editor/rich-text-editor.module';
import { LoadingComponent } from './components/loading/loading.component';

registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IndustryComponent,
    ProductComponent,
    ChatComponent,
    NavbarComponent,
    DigitalQuotationComponent,
    QuotationMessageComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes, { useHash: true, scrollPositionRestoration: 'top' }),
    FormsModule,
    ReactiveFormsModule,
    RichTextEditorModule,
        
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-Ar' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
