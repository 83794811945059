import { Injectable } from '@angular/core';
import { HeadersListResponse, ProductHeader, ProductResponse } from '../models/products';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private api: ApiService) { }

  public async getHeaders(industryId: number) {
    const res = await this.api.get<HeadersListResponse>('headers/industry/' + industryId);
    if (res.code === 200 || res.code === 0) {
      return res.listHeaders;
    } else {
      throw new Error(res.message);
    }
  }

  public async getProduct(productId: number) {
    const res = await this.api.get<ProductResponse>('product/' + productId);
    if (res.code === 200) {
      return res.product;
    } else {
      throw new Error(res.message);
    }
  }
}
