import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { AboutUs } from '../models/about-us';
import { VideoCarousel } from '../models/carousel-video';
import { ContactMail } from '../models/communication';
import { Industry } from '../models/industries';
import { SuccessCase } from "../models/success-cases";
import { HomeService } from '../services/home.service';
import { CommunicationService } from '../services/communication.service';
import { IndustryService } from '../services/industry.service';
import { NavbarService } from '../services/navbar.service';
import { Subscription } from 'rxjs';

declare const bootstrap: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.keyScroll(event);
  }

  private subscription: Subscription = new Subscription();

  public canScroll: boolean = true;
  public sections: string[] = ['Carousel', 'Industries', 'SuccessCases', 'AboutUs', 'Contact'];
  public currentSectionIndex: number = NaN;

  public carouselVideos: VideoCarousel[] = [];
  public carouselVideosPause: boolean = false;
  public industryPage = 0;
  public industries: Industry[][] = [];
  public allIndustries: Industry[] = [];
  public aboutUs: AboutUs = {} as AboutUs;
  public successCases: SuccessCase[] = [];
  public contactForm: FormGroup;
  public loading:boolean=true
  private videoCarousel: any;

  constructor(
    private industryService: IndustryService,
    private homeService: HomeService,
    private communicationService: CommunicationService,
    private chRef: ChangeDetectorRef,
    private navbarService: NavbarService,
    fb: FormBuilder,
  ) {
    this.contactForm = fb.group({
      name: [''],
      email: [''],
      industry: [0],
      subject: [''],
      message: ['']
    })
    
  }

  ngOnInit(): void {
    this.loadCarouselVideos();
    this.loadIndustries();
    this.loadAboutUs();
    this.loadSuccessCases();
  }

  ngAfterViewInit(): void {
    const main = document.getElementsByTagName('main')[0];
    main.addEventListener('wheel', (event) => this.mouseScroll(event));
    this.subscription = this.navbarService.navchange.subscribe((section) => {
      if (this.currentSectionIndex !== this.sections.indexOf(section)) {
        this.scrollTo(section);
      }
    });
    if (this.navbarService.section !== 'Carousel') {
      setTimeout(() => {
        this.scrollTo(this.navbarService.section);
      }, 500);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //#region Scroll related

  private mouseScroll(event: any) {
    event.preventDefault();
    const next = event.deltaY > 0 ? true : event.deltaY < 0 ? false : undefined;
    if (next === undefined) { return; }
    this.scrollSection(next);
  }

  public keyScroll(event: KeyboardEvent) {
    const { key } = event;
    if (['PageDown', 'PageUp', 'ArrowUp', 'ArrowDown'].includes(key)) {
      event.preventDefault();
      this.canScroll = true;
      this.scrollSection(['PageDown', 'ArrowDown'].includes(key));
    }
  }

  public scrollSection(next: boolean) {
    if (!this.currentSectionIndex) { this.currentSectionIndex = 0; }
    if (!this.canScroll) {
      return;
    }
    this.canScroll = false;
    setTimeout(() => { this.canScroll = true }, 1000);
    let scrollIndex = this.currentSectionIndex;
    scrollIndex += (next ? 1 : -1);
    const nextScroll = this.sections[scrollIndex];
    if (nextScroll) {
      this.scrollTo(nextScroll);
    }
  }

  public scrollTo(sectionName: string) {
    const section = document.getElementById('section' + sectionName) as HTMLDivElement;
    section.scrollIntoView();
    this.currentSectionIndex = this.sections.indexOf(sectionName);
    this.navbarService.section = sectionName;
  }
  //#endregion

  private async loadCarouselVideos() {
    this.carouselVideos = await this.homeService.getCarouselVideos();
    if (this.carouselVideos.length) {
      this.chRef.detectChanges();
      const carouselElement = document.querySelector('#carouselVideos');
      this.videoCarousel = new bootstrap.Carousel(carouselElement, {
        interval: 3000,
        pause: false
      });
      this.videoCarousel.cycle();
    }
   

  }
  imagenCargada(event: Event) {
    // Esta función se ejecutará cuando la imagen esté completamente cargada.
    console.log('Imagen cargada:', event.target);
    // Puedes realizar cualquier acción que necesites aquí.
  }

  public playPauseVideoCarousel() {
    const videos = document.getElementsByClassName('carousel-video');
    if (this.carouselVideosPause) {
      this.videoCarousel.cycle();
      for (let i = 0; i < videos.length; i++) {
        const video = videos[i] as HTMLVideoElement;
        video.play();
      }
    } else {
      this.videoCarousel.pause();
      for (let i = 0; i < videos.length; i++) {
        const video = videos[i] as HTMLVideoElement;
        video.pause();
      }
    }
    this.carouselVideosPause = !this.carouselVideosPause;
  }

  private async loadIndustries() {
    try {
      let industries = await this.industryService.getList();
      this.allIndustries = industries;
      for (let i = 0; i < industries.length; i += 6) {
        this.industries.push(industries.slice(i, i + 6));
      }
    } catch (error) {
      console.log(error);
    }
  }

  private async loadAboutUs() {
    try {
      this.aboutUs = await this.homeService.getAboutUs();
    } catch (error) {
      console.log(error);
    }
  }

  private async loadSuccessCases() {
    try {
      this.successCases = await this.homeService.getSuccessCases();
    } catch (error) {
      console.log(error);
    }
    setTimeout(()=>{
      this.loading=false
    },1500)
  }

 

  public async sendMail() {
    const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    const getVal = (field: string) => this.contactForm.get(field)?.value;
    const name: string = getVal('name').trim();
    const email: string = getVal('email').trim();
    const industryId: number = getVal('industry');
    const subject: string = getVal('subject').trim();
    const message: string = getVal('message').trim();
    let msg = '';

    if (!name) {
      msg += 'Complete su nombre<br/>';
    }
    if (!email) {
      msg += 'Complete su mail<br/>';
    } else if (!emailRegex.test(email)) {
      msg += 'El mail ingresado no es válido<br/>';
    }
    if (!industryId) {
      msg += 'Seleccione una industria<br/>';
    }
    if (!subject) {
      msg += 'Complete el asunto<br/>';
    }
    if (!message) {
      msg += 'Escriba un mensaje';
    }

    if (msg) {
      Swal.fire({ icon: 'warning', html: msg });
      return;
    }

    Swal.fire({
      title: 'Enviando mensaje...',
      didOpen: () => Swal.showLoading()
    });
    try {
      const mail: ContactMail = {
        name,
        email,
        industryId,
        subject,
        message
      };
      await this.communicationService.sendContactMail(mail);
      this.contactForm.reset();
      Swal.fire({
        icon: 'success',
        title: 'Mensaje enviado'
      })
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'Ha ocurrido un error al enviar el mensaje, por favor intente nuevamente'
      });
    }
  }

}
