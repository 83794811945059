import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreadcrumbSection, NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public menuOpen: boolean = false;
  public breadcrumbs: BreadcrumbSection[] = [];

  constructor(
    private navbarService: NavbarService,
    router: Router
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const navbar = document.getElementById('navbar') as HTMLElement;
        if (event.url.includes('product')) {
          navbar.classList.add('background');
        } else {
          navbar.classList.remove('background');
        }
      }
    });
  }

  ngOnInit(): void {
    this.navbarService.breadcrumbsChange.subscribe(breadcrumbs => this.breadcrumbs = breadcrumbs);
    this.navbarService.navchange.subscribe(section => {
      this.navbarService.breadcrumbs = [];
      this.setActive(section);
    });
    this.setActive('Carousel');
  }

  public setSection(name: string) {
    this.menuOpen = false;
    this.navbarService.section = name
  }

  public setActive(section: string) {
    const navbar = document.getElementById('navbar') as HTMLElement;
    const links = Array.from(navbar.getElementsByTagName('li'));
    const activeLink = document.getElementById('navlink-' + section);

    if (['Carousel', 'Contact'].includes(section)) {
      navbar.classList.add('white');
    } else {
      navbar.classList.remove('white');
    }

    links.forEach(element => {
      element.classList.remove('active');
    });
    activeLink?.classList.add('active');
  }

}
