<header></header>
<main style="overflow-y: auto;">
  <app-loading *ngIf="loading"></app-loading>
  <!-- #region Carrusel de videos -->
  <section class="video-carousel" id="sectionCarousel">
    <div class="carousel slide carousel-fade" id="carouselVideos" *ngIf="carouselVideos.length">
      <div class="carousel-indicators">
        <button *ngFor="let video of carouselVideos; let i = index" type="button" data-bs-target="#carouselVideos"
          [attr.data-bs-slide-to]="i" [ngClass]="{'active': i == 0}"
          [attr.aria-current]="i == 0 ? true : null"></button>
      </div>
      <div class="carousel-inner">
        <div *ngFor="let video of carouselVideos; let i = index" class="carousel-item" [ngClass]="{'active': i == 0}">
          <div class="video-carousel-slide">
            <video *ngIf="video.video.url" autoplay [muted]="true" playsinline loop class="carousel-video" >
              <source [src]="video.video.url" type="video/mp4">
            </video>
            <h2>{{ video.title }}</h2>
            <div class="video-items-container">
              <div class="video-item" *ngFor="let item of video.texts">
                <img src="/assets/img/bubbles.png">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="/assets/img/section-footer-white.png" class="background">
    <button class="know-more" (click)="scrollTo('Industries')">Conocer más</button>
    <button class="carousel-play" (click)="playPauseVideoCarousel()">
      <span class="material-icons">{{ carouselVideosPause ? 'play_circle' : 'pause' }}</span>
    </button>
    <img src="/assets/img/arrow-down.png" class="arrow blinking" (click)="scrollTo('Industries')">
  </section>
  <!-- #endregion Carrusel de videos -->
  <!-- #region Industrias -->
  <section class="industries" id="sectionIndustries">
    <h2 class="section-name">Industrias</h2>
    <!-- <img src="/assets/img/arrow-down.png" class="arrow-up blinking" style="rotate: 180deg;"  (click)="scrollTo('Carousel')"> -->
    <div class="industries-container" *ngFor="let page of industries; let i = index"
      [ngStyle]="{'display': i != industryPage ? 'none' : 'flex'}">
      <div class="industry-item" *ngFor="let industry of page" [routerLink]="['industry', industry.industryID]"
        [ngStyle]="{'background-image': 'url(' + industry.images[0].url + ')'}">
        <p>{{ industry.name }}</p>
      </div>
      <img class="page-control prev blinking" *ngIf="i != 0" (click)="industryPage = industryPage - 1"
        src="/assets/img/arrow-left.png">
      <img class="page-control next blinking" *ngIf="i != industries.length - 1"
        (click)="industryPage = industryPage + 1" src="/assets/img/arrow-right.png">
    </div>
    <div id="carouselIndustries" class="industries-mobile carousel slide" data-bs-ride="carousel"
      *ngIf="allIndustries.length">
      <div class="carousel-indicators">
        <button *ngFor="let industry of allIndustries; let i = index" type="button" data-bs-target="#carouselIndustries"
          [attr.data-bs-slide-to]="i" [ngClass]="{'active': i == 0}"
          [attr.aria-current]="i == 0 ? true : null"></button>
      </div>
      <div class="carousel-inner">
        <div *ngFor="let industry of allIndustries; let i = index" class="carousel-item" data-bs-interval="5000"
          [ngClass]="{'active': i == 0}">
          <div class="industry-item" [routerLink]="['industry', industry.industryID]"
            [ngStyle]="{'background-image': 'url(' + industry.images[0].url + ')'}">
            <p>{{ industry.name }}</p>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev blinking" type="button" data-bs-target="#carouselIndustries"
        data-bs-slide="prev">
        <img src="/assets/img/arrow-left.png" alt="">
      </button>
      <button class="carousel-control-next blinking" type="button" data-bs-target="#carouselIndustries"
        data-bs-slide="next">
        <img src="/assets/img/arrow-right.png" alt="">
      </button>
    </div>
    <!-- <img src="/assets/img/arrow-down.png" class="arrow-down blinking"   (click)="scrollTo('SuccessCases')"> -->
  </section>
  <!-- #endregion Industrias -->
  <!-- #region Casos de éxito -->
  <section class="success-cases" id="sectionSuccessCases">
    <h2 class="section-name">Casos de éxito</h2>
    <!-- <img src="/assets/img/arrow-down.png" class="arrow-up blinking" style="rotate: 180deg;"  (click)="scrollTo('Industries')"> -->
    <div id="carouselSuccessCases" class="carousel slide" data-bs-ride="carousel" *ngIf="successCases.length">
      <div class="carousel-indicators">
        <button *ngFor="let successCase of successCases; let i = index" type="button"
          data-bs-target="#carouselSuccessCases" [attr.data-bs-slide-to]="i" [ngClass]="{'active': i == 0}"
          [attr.aria-current]="i == 0 ? true : null"></button>
        <!-- <button type="button" data-bs-target="#carouselSuccessCases" attr.data-bs-slide-to="{{i}}"></button> -->
      </div>
      <div class="carousel-inner">
        <div *ngFor="let successCase of successCases; let i = index" class="carousel-item" data-bs-interval="5000"
          [ngClass]="{'active': i == 0}">
          <div class="success-case">
            <div class="success-case-image" [ngStyle]="{'background-image': 'url(' + successCase.image.url + ')'}">
            </div>
            <div class="success-case-details">
              <img src="/assets/img/bubbles.png">
              <h3>{{ successCase.text }}</h3>
              <div class="success-case-item" *ngFor="let item of successCase.successCaseValues">
                <span class="item-name">{{ item.name }}:</span>
                <span class="item-value">{{ item.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev blinking" type="button" data-bs-target="#carouselSuccessCases"
        data-bs-slide="prev">
        <img src="/assets/img/arrow-left.png" alt="">
      </button>
      <button class="carousel-control-next blinking" type="button" data-bs-target="#carouselSuccessCases"
        data-bs-slide="next">
        <img src="/assets/img/arrow-right.png" alt="">
      </button>
    </div>
    <!-- <img src="/assets/img/arrow-down.png" class="arrow-down blinking"   (click)="scrollTo('AboutUs')"> -->
  </section>
  <!-- #endregion Casos de éxito -->
  <!-- #region Nosotros -->
  <section class="about-us" id="sectionAboutUs">
    <h2 class="section-name">Nosotros</h2>
    <!-- <img src="/assets/img/arrow-down.png" class="arrow-up blinking" style="rotate: 180deg;"  (click)="scrollTo('SuccessCases')"> -->
    <p class="body" [innerHTML]="aboutUs.body"></p>
    <h3 class="title">{{ aboutUs.title }}</h3>
    <img src="/assets/img/professionals.png" class="sub-img img1">
    <h4 class="sub-title sub1">{{ aboutUs.sub1 }}</h4>
    <p class="sub-body body1">{{ aboutUs.body1 }}</p>
    <img src="/assets/img/engineering.png" class="sub-img img2">
    <h4 class="sub-title sub2">{{ aboutUs.sub2 }}</h4>
    <p class="sub-body body2">{{ aboutUs.body2 }}</p>
    <img src="/assets/img/laboratory.png" class="sub-img img3">
    <h4 class="sub-title sub3">{{ aboutUs.sub3 }}</h4>
    <p class="sub-body body3">{{ aboutUs.body3 }}</p>
    <img src="/assets/img/manufacturing.png" class="sub-img img4">
    <h4 class="sub-title sub4">{{ aboutUs.sub4 }}</h4>
    <p class="sub-body body4">{{ aboutUs.body4 }}</p>
    <!-- <img src="/assets/img/arrow-down.png" class="arrow-down blinking"   (click)="scrollTo('Contact')"> -->
  </section>
  <!-- #endregion Nosotros -->
  <!-- #region Contacto -->
  <section class="contact" id="sectionContact">
    <h2 class="section-name">Contacto</h2>
    <!-- <img src="/assets/img/arrow-left-white.png" class="arrow-up-f blinking" style="rotate: 90deg;"  (click)="scrollTo('AboutUs')"> -->
    <div class="content">
      <form [formGroup]="contactForm" class="contact-form">
        <div class="form-input">
          <span>Nombre:</span>
          <input type="text" formControlName="name">
        </div>
        <div class="form-input">
          <span>Correo electrónico:</span>
          <input type="text" formControlName="email">
        </div>
        <div class="form-input">
          <span>Rubro industrial:</span>
          <!-- <input type="text" formControlName="industry"> -->
          <select formControlName="industry">
            <option *ngFor="let item of allIndustries" [value]="item.industryID">{{ item.name }}</option>
          </select>
        </div>
        <div class="form-input">
          <span>Asunto:</span>
          <input type="text" formControlName="subject">
        </div>
        <div class="form-input">
          <span>Mensaje:</span>
          <textarea formControlName="message"></textarea>
        </div>
        <button (click)="$event.preventDefault(); sendMail()">Enviar</button>
      </form>
      <div class="right-content">
        <h2>FluidsControl S.A.</h2>
        <h3>Dirección comercial:</h3>
        <p>López y Planes 5558, Billinghurts, General San Martín (1650) Buenos Aires, Argentina</p>
        <h3>Teléfono:</h3>
        <p><a href="tel:+541173977886" >+54 11 7397-7886</a></p>
        <h3>eMail corporativo:</h3>
        <p><a href="mailto:institucional@fdscontrol.com" >institucional@fdscontrol.com</a></p>
      </div>
    </div>
  </section>
  <!-- #endregion Contacto -->
  <footer>
    ©2021 FluidsControl S.A. de Argentina - todos los derechos reservados
  </footer>
</main>
