import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { DigitalQuotation, QuotationItem, QuotationMessage } from '../models/digital-quotation';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Swal from 'sweetalert2';
import { QuotationService } from '../services/quotation.service';


@Component({
  selector: 'app-digital-quotation',
  templateUrl: './digital-quotation.component.html',
  styleUrls: ['./digital-quotation.component.scss']
})
export class DigitalQuotationComponent implements OnInit {
  private token: string = '';
  public quotation?: DigitalQuotation;
  public quotationMessages: QuotationMessage[] = [];
  public exporting: boolean = false;
  public fdslogo: string = '';
  public files: File[] = [];

  constructor(
    private quotationService: QuotationService,
    private route: ActivatedRoute,
    private chRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'] || '';
      this.loadQuotation(this.token);
    });
  }

  private calculatePrice(item: QuotationItem) {
    item.unitPrice = Number((item.unitPrice - (item.unitPrice * (item.discount / 100))).toFixed(2));
    item.partialTotal = item.unitPrice * item.quantity;
  }

  private async loadQuotation(token: string) {
    Swal.fire({ title: 'Cargando datos', allowEnterKey: false, allowEscapeKey: false, allowOutsideClick: false });
    Swal.showLoading();
    try {
      const quotation = await this.quotationService.getDigitalQuotation(token);
      console.log(quotation)
      quotation.industry.industryImageUrl = await this.getBase64ImageFromUrl(quotation.industry.industryImageUrl);
      quotation.industry.products.forEach(async p => {
        console.log(p.imageUrl.replace('\\','/'))
        const b64 = await this.getBase64ImageFromUrl(p.imageUrl.replace('\\','/'));
        p.imageUrl = b64;
      });
      quotation.quotationItems.forEach(this.calculatePrice);
      this.quotation = quotation;
      Swal.close();
      this.loadMessages();
    } catch (error: any) {
      Swal.close();
      console.error(error);
      Swal.fire({ toast: true, title: error.toString(), icon: 'error' })
    }
  }

  private async getBase64ImageFromUrl(imageUrl: string) {
    return imageUrl;
    // const res = await fetch(imageUrl, { mode: 'no-cors' });
    // const blob = await res.blob();
    // return new Promise<any>((resolve, reject) => {
    //   const reader = new FileReader();
    //   reader.addEventListener('load', function () { resolve(reader.result); }, false);
    //   reader.onerror = () => reject(this);
    //   reader.readAsDataURL(blob);
    // });
  }

  public scrollToElement(el: HTMLElement) {
    if (!el) return;
    el.scrollIntoView({ behavior: 'smooth' });
  }

  private async loadMessages() {
    try {
      const messages = await this.quotationService.getDigitalQuotationMessages(this.token);
      messages.forEach(m => m.companyName = this.quotation!.quotation.clientLegalName);
      this.quotationMessages = messages;
    } catch (error: any) {
      Swal.fire({ toast: true, title: error.toString(), icon: 'error' })
    }
    this.chRef.detectChanges();
  }

  public selectFile(target: EventTarget | null) {
    const input = target as HTMLInputElement;
    const files = Array.from(input.files!);
    this.files = [...this.files, ...files];
    input.value = '';
  }

  public removeFile(file: File) {
    this.files = this.files.filter(f => f != file);
  }

  public async sendMessage(textarea: HTMLTextAreaElement) {
    const text = textarea.value;
    Swal.fire({
      title: 'Enviando mensaje',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    try {
      await this.quotationService.postDigitalQuotationMessage(this.token, text, this.files);
    } catch (error: any) {
      Swal.fire({ toast: true, title: error.toString(), icon: 'error' })
    }
    await this.loadMessages();
    swal.close();
    textarea.value = '';
  }

  public async exportToPDF() {
    this.exporting = true;
    Swal.fire({
      title: 'Exportando a PDF',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    swal.showLoading();
    (document.getElementById('viewportMeta') as any)['content'] = 'width=1920';

    const doc = new jsPDF('p', 'pt', 'a4');
    // get total size without margin
    const totalPageWidth = doc.internal.pageSize.getWidth();
    const totalPageHeight = doc.internal.pageSize.getHeight();
    // convert margins from px to doc unit mantaining ratio
    const ratio = (5 / 6); // px to mm ration
    const marginX = totalPageWidth * (57 / 720);
    const marginY = totalPageHeight * (50 / 720);
    // get total size with margin
    const pageWidth = totalPageWidth - (marginX * 2);

    // const pageHeight = totalPageHeight - (marginY * 2);

    let indexY = marginY; // height cursor for new inserts

    const timeout = new Promise(resolve => setTimeout(() => resolve(true), 500));
    await timeout;

    const fnGetCanvasFromId = (id: string) => html2canvas(document.getElementById(id)!); // function to get canvas element from element id

    const footerImage = await fnGetCanvasFromId('summaryFooter');
    const footerProps = doc.getImageProperties(footerImage);
    const footerHeight = (footerProps.height * pageWidth) / footerProps.width;

    const pageHeight = totalPageHeight - ((marginY * 2) + (10 * ratio));
    // debugger;
    const addFooter = () => {
      doc.addImage(footerImage, 'JPEG', marginX, pageHeight + (10 * ratio), pageWidth, footerHeight);
    };

    const addImage = (img: HTMLCanvasElement, prevent?: boolean) => { // adds canvas image using the same aspect ratio
      // get image width and set height to auto
      const imgProps = doc.getImageProperties(img);
      const height = (imgProps.height * pageWidth) / imgProps.width;
      if (indexY + height > pageHeight) {
        doc.addPage();
        addFooter();
        indexY = marginY;
        if (prevent) return true;
      }
      doc.addImage(img, 'JPEG', marginX, indexY, pageWidth, height);
      indexY += height; // updates "Y" cursor
      return false;
    };

    // doc.addImage(await fnGetCanvasFromId('quotationCover'), 'JPEG', 0, 0, totalPageWidth, totalPageHeight);
    // doc.addPage();

    // doc.addImage(await fnGetCanvasFromId('portfolio'), 'JPEG', 0, 0, totalPageWidth, totalPageHeight);
    // doc.addPage();
    addFooter();

    addImage(await fnGetCanvasFromId('summaryHeaderPre')); indexY += 10 * ratio;
    addImage(await fnGetCanvasFromId('summaryHeader')); indexY += 30 * ratio;
    addImage(await fnGetCanvasFromId('preTableMessage')); indexY += 10 * ratio;

    const trHead = await html2canvas(document.querySelector('thead tr')!); // table header row
    addImage(trHead);

    const trs = Array.from(document.querySelectorAll('tbody tr')) as HTMLElement[];
    for (let i = 0; i < trs.length; i++) {
      indexY += 10 * ratio;
      const tr = trs[i];
      const trImg = await html2canvas(tr);
      if (addImage(trImg, true)) { // if new page then is not inserted
        addImage(trHead);
        indexY += 10 * ratio;
        addImage(trImg);
      }
    }
    indexY += 30 * ratio;

    // addImage(await fnGetCanvasFromId('postTableMessage')); indexY += 30 * ratio;

    const descriptions = Array.from(document.querySelectorAll('.items-description-container .item-description')) as HTMLElement[];
    for (let i = 0; i < descriptions.length; i++) {
      indexY += 10 * ratio;
      const desc = descriptions[i];
      const descImg = await html2canvas(desc);
      addImage(descImg);
    }
    indexY += 30 * ratio;

    addImage(await fnGetCanvasFromId('sellConditions')); indexY += 30 * ratio;
    // addImage(await fnGetCanvasFromId('summaryFooter'));

    doc.save(`Presupuesto N${this.quotation?.quotation.quotationID}.pdf`);

    (document.getElementById('viewportMeta') as any)['content'] = 'width=device-width';
    this.exporting = false;
    swal.close();
  }

}
