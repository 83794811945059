import { Injectable } from '@angular/core';
import { ContactMail } from '../models/communication';
import { ApiResponse } from '../models/response';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(private api: ApiService) { }

  public async sendContactMail(mail: ContactMail) {
    const res = await this.api.post<ApiResponse>('mails/contact', mail);
    if (res.code === 200) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  }
}
