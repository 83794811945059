<main>
  <app-loading *ngIf="loading"></app-loading>
  <section id="industry-intro">
    <video *ngIf="videoUrl" autoplay muted loop>
      <source [src]="videoUrl" type="video/mp4">
    </video>
    <!-- <h2 class="industry-name">{{name}}</h2> -->
    <h2 class="industry-name">
      {{ name }}
    </h2>
    <button class="know-more" (click)="scrollTo('products')">Ver productos</button>
    <img src="/assets/img/arrow-down.png" class="arrow blinking" (click)="scrollTo('products')">
    <img src="/assets/img/section-footer-white.png" class="background">
    
    <div class="intro-headers">
      <div class="intro-header-item" *ngFor="let header of headers">
        <img src="/assets/img/bubbles-white.png"> {{ header.name }}
      </div>
    </div>
  </section>
  <!-- <section id="industry-products" style="position: relative;">
    <div class="movile">
    
      <div class="carousel-container ">
        <div class="carouselMobile">
      <div class="slide s-scroll" *ngFor="let product of productPage2;let i = index"  >
        <div class="header-c" style="background-color: #00b0f0; color: white; text-transform:uppercase;text-align:center;line-height:34px; display: flex; align-items: center; justify-content: center;">
          <h4 style="margin: 10px;"><strong>{{name}}</strong>{{'/'+product.headerName}}</h4>
        </div>
        <div class="row"  style="text-align: center; margin-left: 10%; margin-right: 10%;" >         
          <h3 >{{product.name}}</h3>
        </div>
        <div class="w-100 d-flex" style="flex-direction: column;justify-content: center;align-items: center;" >
        <div class="row content-c text-white" *ngFor="let p of productPage2[i].product;let j = index" >
          <div class="row" *ngIf="j%2===0 || j===0" [routerLink]="['product', p.productId]">

            <div class="col-3" style="display: flex; justify-content: end;align-items: center;" >
              <img class="img-c" [src]="p.logo?.url" alt="Slide">
            </div>
            <div class="col" style="display: flex; justify-content: start; align-items: center;"  >
              <div>
                <h5 style="font-family:'Exo 2 Medium' ;  font-size: 20px; line-height: 24px;">{{p.name}}</h5>
                <p style="font-size: 15px;line-height: 18px; font-family: 'Exo 2 Extra Light Condensed';">{{ p.description }}</p>
              </div>
            </div>
            <div style="height: 20px;"></div>
          </div>
          <div class="row" *ngIf="j%2>0 "  [routerLink]="['product', p.productId]">

            <div class="col"  style="display: flex; justify-content: end; align-items: center;">
              <div>
                <h5 style="font-family:'Exo 2 Medium' ;  font-size: 20px; line-height: 24px;">{{p.name}}</h5>
                <p style="font-size: 15px;line-height: 18px; font-family: 'Exo 2 Extra Light Condensed';">{{ p.description }}</p>
              </div>
            </div>
            <div class="col-3" style="display: flex; justify-content: start;align-items: center;">
              <img class="img-c" [src]="p.logo?.url" alt="Slide">
            </div>
            <div style="height: 20px;"></div>
          </div>
          
          <div >
            
        </div>
          </div>
          
        </div>
        </div>
    </div>
</div>
<div class="button-c" (click)="prevSlideMobile()" style="position: absolute; top: 10px; left: 20px; z-index: 1000; cursor: pointer;">
  <img class="blinking" src="/assets/img/arrow-left.png" alt="">    
</div>
    <div class="button-c" (click)="nextSlideMobile()" style="position: absolute; top: 10px; right: 20px; z-index: 1000; cursor: pointer;">
      <img class="blinking" src="/assets/img/arrow-right.png" alt="">
    </div>
    </div> 

    
     <div id="headersCarousel" *ngIf="headers.length">
      <ng-container *ngFor="let header of headers; let i = index">
        <div class="header-inner" *ngIf="selectedHeaderIndex == i">
          <h2>
            <strong>{{name}}</strong> / {{ header.name }}
            <div class="header-controls">
              <button (click)="changePage()" class="blinking">
                <img src="/assets/img/arrow-left-white.png" alt="">
              </button>
              <button (click)="changePage(true)" class="blinking">
                <img src="/assets/img/arrow-right-white.png" alt="">
              </button>
            </div>
          </h2> -->
          <!-- #region Desktop -->
          <!-- <div id="familyPage" class="carousel slide" *ngIf="header.familyProducts.length">
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let page of header.familyProductsPage; let j = index"
                [ngClass]="{'active': j == 0}">
                <div class="header-section">
                  <div class="product-family" *ngFor="let family of page">
                    <h3>{{ family.name }}</h3>
                    <div class="family-products">
                      <div class="family-product-item" *ngFor="let product of family.products"
                        [routerLink]="['product', product.productId]">
                        <h4>{{ product.name }}</h4>
                        <p>{{ product.description }}</p>
                        <div class="img-container" *ngIf="product.logo?.url">
                          <div class="background-img"
                            [ngStyle]="{'background-image': 'url(' + product.logo?.url + ')'}">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev blinking" type="button" data-bs-target="#familyPage"
              data-bs-slide="prev">
              <img src="/assets/img/arrow-left.png" alt="">
            </button>
            <button class="carousel-control-next blinking" type="button" data-bs-target="#familyPage"
              data-bs-slide="next">
              <img src="/assets/img/arrow-right.png" alt="">
            </button>
          </div> -->
          <!-- #endregion Desktop -->
          <!-- <div id="productFamily" class="carousel slide" *ngIf="header.familyProducts.length">
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let family of header.familyProducts; let j = index"
                [ngClass]="{'active': j == 0}">
                <div class="header-section">
                  <div class="product-family">
                    <h3>{{ family.name }}</h3>
                    <div class="family-products">
                      <div class="family-product-item" *ngFor="let product of family.products"
                        [routerLink]="['product', product.productId]">
                        <h4>{{ product.name }}</h4>
                        <p>{{ product.description }}</p>
                        <div class="img-container" *ngIf="product.logo?.url">
                          <div class="background-img"
                            [ngStyle]="{'background-image': 'url(' + product.logo?.url + ')'}">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev blinking" type="button" data-bs-target="#productFamily"
              data-bs-slide="prev">
              <img src="/assets/img/arrow-left.png" alt="">
            </button>
            <button class="carousel-control-next blinking" type="button" data-bs-target="#productFamily"
              data-bs-slide="next">
              <img src="/assets/img/arrow-right.png" alt="">
            </button>
          </div>
        </div>        
      </ng-container>       -->
    <!-- </div> -->

    <!-- carusel des -->
   <!-- <div  class="descktop">
      
      <div class="carousel-container ">
        <div class="carousel">
      <div class="slide s-scroll" *ngFor="let product of grupo;let i = index"  >
        
        <div class="header-c" style="background-color: #00b0f0; color: white; text-transform:uppercase;text-align:center;line-height:34px; display: flex; align-items: center; justify-content: center;">
          <h4 style="margin: 10px;"><strong>{{name}}</strong>{{'/'+product.headerName}}</h4>
        </div>
        <div class="row" >  
          <div class=" col-lg-6 col-sm-12 d-flex .s-scroll" style="flex-direction: column;text-align: center;align-items: center; "*ngFor="let familia of product.productos;let j = index">   
          <h3>{{familia.name}}</h3>  
            <div class="row content-c text-white" *ngFor="let p of familia.product">
              <div class="col-3" style="display: flex; justify-content: end;align-items: center;" >
                <img class="img-c" [src]="p.logo?.url" alt="Slide">
              </div>
              <div class="col" style="display: flex; justify-content: start; align-items: center;"  >
                <div>
                  <h5 style="font-family:'Exo 2 Medium' ;  font-size: 20px; line-height: 24px;">{{p.name}}</h5>
                  <p style="font-size: 15px;line-height: 18px; font-family: 'Exo 2 Extra Light Condensed';">{{ p.description }}</p>
                </div>
              </div>
              <div style="height: 130px;"></div>
            </div>
        </div>
       
      </div> 
        </div>
    </div>
</div>
<div class="button-c" (click)="prevSlide()" style="position: absolute; top: 10px; left: 20px; z-index: 1000; cursor: pointer;">
  <img class="blinking" src="/assets/img/arrow-left.png" alt="">    
</div>
    <div class="button-c" (click)="nextSlide()" style="position: absolute; top: 10px; right: 20px; z-index: 1000; cursor: pointer;">
      <img class="blinking" src="/assets/img/arrow-right.png" alt="">
    </div>
  
       
    </div>
  </section> -->
  <section id="industry-products">
    <div id="headersCarousel" *ngIf="headers.length">
      <ng-container *ngFor="let header of headers; let i = index">
        <div class="header-inner" *ngIf="selectedHeaderIndex == i">
          <h2>
            <strong>{{name}}</strong> / {{ header.name }}
            <div class="header-controls">
              <button (click)="changePage()" class="blinking" style="display: none;">
                <img src="/assets/img/arrow-left-white.png" alt="" >
              </button>
              <button (click)="changePage(true)" class="blinking" style="display: none;">
                <img src="/assets/img/arrow-right-white.png" alt="">
              </button>
            </div>
          </h2>
          <!-- #region Desktop -->
          <div id="familyPage" class="carousel slide" *ngIf="header.familyProducts.length">
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let page of header.familyProductsPage; let j = index"
                [ngClass]="{'active': j == 0}">
                <div class="header-section">
                  <div class="product-family" *ngFor="let family of page">
                    <h3>{{ family.name }}</h3>
                    <div class="family-products">
                      <div class="family-product-item" *ngFor="let product of family.products"
                        [routerLink]="['product', product.productId]">
                        <h4>{{ product.name }}</h4>
                        <p>{{ product.description }}</p>
                        <div class="img-container" *ngIf="product.logo?.url">
                          <div class="background-img"
                            [ngStyle]="{'background-image': 'url(' + product.logo?.url + ')'}">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev blinking" type="button" data-bs-target="#familyPage"
              data-bs-slide="prev" (click)="backPage()">
              <img src="/assets/img/arrow-left.png" alt="">
            </button>
            <button class="carousel-control-next blinking" type="button" data-bs-target="#familyPage"
              data-bs-slide="next" (click)="nextPage()">
              <img src="/assets/img/arrow-right.png" alt="">
            </button>
          </div>
          <!-- #endregion Desktop -->
          <div id="productFamily" class="carousel slide" *ngIf="header.familyProducts.length">
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let family of header.familyProducts; let j = index"
                [ngClass]="{'active': j == 0}">
                <div class="header-section">
                  <div class="product-family">
                    <h3>{{ family.name }}</h3>
                    <div class="family-products">
                      <div class="family-product-item" *ngFor="let product of family.products"
                        [routerLink]="['product', product.productId]">
                        <h4>{{ product.name }}</h4>
                        <p>{{ product.description }}</p>
                        <div class="img-container" *ngIf="product.logo?.url">
                          <div class="background-img"
                            [ngStyle]="{'background-image': 'url(' + product.logo?.url + ')'}">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev blinking" type="button" data-bs-target="#productFamily"
              data-bs-slide="prev" (click)="backPageM()">
              <img src="/assets/img/arrow-left.png" alt="" >
            </button>
            <button class="carousel-control-next blinking" type="button" data-bs-target="#productFamily"
              data-bs-slide="next" (click)="nextPageM()">
              <img src="/assets/img/arrow-right.png" alt="" >
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
</main>