import { Injectable } from '@angular/core';
import { ChatListResponse, ChatMessage, ChatToken, CountriesResponse, IndustryVendor, VendorStatusResponse } from '../models/chat';
import { ApiResponse } from '../models/response';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private api: ApiService,private http:HttpClient) { }


  apiSocket=environment.apiSocket;

  private getTokenList(): ChatToken[] {
    const json = window.localStorage.getItem('chatTokens');
    if (json) {
      return JSON.parse(json) as ChatToken[];
    } else {
      return [];
    }
  }

  public setLocalToken(token: ChatToken): void {
    let tokens = this.getTokenList();
    tokens = tokens.filter(t => t.industryId != token.industryId); // Elimino el token con el industryId del actual en caso de que exista uno
    tokens.push(token);
    window.localStorage.setItem('chatTokens', JSON.stringify(tokens));
  }

  public getLocalToken(industryId: number) {
    const tokenList = this.getTokenList();
    return tokenList.find(t => t.industryId === industryId);
  }

  public async getOnlineToken() {
    const { newToken } = await this.api.get<{ newToken: string }>('chat/generateToken');
    return newToken;
  }

  public getNewVendor(industryId: number) {
    return this.api.get<IndustryVendor>('chat/industryAccount/' + industryId);
  }

  public getCurrentVendor(token: string) {
    return this.api.get<IndustryVendor>('chat/associatedAccount/' + token);
  }

  public async getVendorStatus(vendorId: number) {
    const res = await this.api.get<VendorStatusResponse>('accounts/accountstatus/' + vendorId);
    if (res.code === 200) {
      return res.accountStatus.online;
    } else {
      throw new Error(res.message);
    }
  }

  public async getCountries() {
    const res = await this.api.get<CountriesResponse>('countries');
    if (res.code === 200) {
      return res.countries;
    } else {
      throw new Error(res.message);
    }
  }

  public getMessages(token: string) {
    return this.api.get<ChatListResponse>('chat/messages/token/' + token);
  }

  public async sendMessage(chatMessage: ChatMessage) {
    const res = await this.api.post<ApiResponse>('chat/sendmessage/client', chatMessage);
    if (res.code === 200) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  }

  public sendSocket(){
    this.http.post(this.apiSocket+"online/message","").subscribe(resp=>{console.log(resp)})
  }

}
