import { Routes } from "@angular/router";
import { ChatComponent } from "./chat/chat.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { DigitalQuotationComponent } from "./digital-quotation/digital-quotation.component";
import { HomeComponent } from "./home/home.component";
import { IndustryComponent } from "./industry/industry.component";
import { ProductComponent } from "./product/product.component";

export const AppRoutes: Routes = [
  {
    path: '', component: NavbarComponent, children: [
      { path: '', component: HomeComponent },
      {
        path: 'industry', component: ChatComponent, children: [
          { path: ':id', component: IndustryComponent },
          { path: ':id/product/:pid', component: ProductComponent },
        ]
      }
    ]
  },
  { path: 'digital-quotation', component: DigitalQuotationComponent }
];
