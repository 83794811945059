<div class="product">
  <div class="module" *ngFor="let module of product.productModules" [attr.data]="module.type"
    [style.color]="module.textColor" [style.backgroundColor]="module.backgroundColor">
    <div class="title1">
      <p>{{ module.title1 }}</p>
    </div>
    <div class="title2">
      <p>{{ module.title2 }}</p>
    </div>
    <div class="description1">
      <p>{{ module.description1 }}</p>
    </div>
    <div class="description2">
      <p>{{ module.description2 }}</p>
    </div>
    <div class="footer1">
      <p>{{ module.footer1 }}</p>
    </div>
    <div class="footer2">
      <p>{{ module.footer2 }}</p>
    </div>
    <div class="image1">
      <div class="image-background" [ngStyle]="{'background-image': 'url(' + module.image1URL + ')'}">
      </div>
    </div>
    <div class="image2">
      <div class="image-background" [ngStyle]="{'background-image': 'url(' + module.image2URL + ')'}">
      </div>
    </div>
    <div class="module-space"></div>
    <div class="module-hr">
      <hr [style.border]="'1px solid ' + module.textColor">
    </div>
  </div>
</div>
