<nav id="navbar" class="nav-desktop">
  <img class="white" src="/assets/img/fds-logo-white.svg" alt="FluidsControl" (click)="setSection('Carousel')">
  <img class="color" src="/assets/img/fds-logo.svg" alt="FluidsControl" (click)="setSection('Carousel')">
  <div class="breadcrumbs-container" *ngIf="breadcrumbs.length">
    <a (click)="setSection('Industries')">Industrias</a>
    <a [routerLink]="[breadcrumbs[0].link]">
      <span class="material-icons">subdirectory_arrow_right</span>{{breadcrumbs[0].name}}
    </a>
    <a *ngIf="breadcrumbs[1]" [routerLink]="[breadcrumbs[1].link]">
      <span class="material-icons">subdirectory_arrow_right</span>{{breadcrumbs[1].name}}
    </a>
  </div>
  <ul class="links">
    <li id="navlink-Carousel" (click)="setSection('Carousel')">Home</li>
    <li id="navlink-Industries" (click)="setSection('Industries')">Industrias</li>
    <li id="navlink-SuccessCases" (click)="setSection('SuccessCases')">Casos de éxito</li>
    <li id="navlink-AboutUs" (click)="setSection('AboutUs')">Nosotros</li>
    <li id="navlink-Contact" (click)="setSection('Contact')">Contacto</li>
  </ul>
</nav>
<nav class="nav-mobile">
  <div class="controls">
    <img class="color" src="/assets/img/fds-logo.svg" alt="FluidsControl" (click)="setSection('Carousel')">
    <span class="material-icons" (click)="menuOpen = true">menu</span>
  </div>
  <div class="backdrop" [ngClass]="{'open': menuOpen}" (click)="menuOpen = false"></div>
  <div class="menu" [ngClass]="{'open': menuOpen}">
    <span class="material-icons" (click)="menuOpen = false">close</span>
    <img class="color" src="/assets/img/fds-logo.svg" alt="FluidsControl" (click)="setSection('Carousel')">
    <ul>
      <li id="navlink-Carousel" (click)="setSection('Carousel')">Home</li>
      <li id="navlink-Industries" (click)="setSection('Industries')">Industrias</li>
      <li id="navlink-SuccessCases" (click)="setSection('SuccessCases')">Casos de éxito</li>
      <li id="navlink-AboutUs" (click)="setSection('AboutUs')">Nosotros</li>
      <li id="navlink-Contact" (click)="setSection('Contact')">Contacto</li>
    </ul>
  </div>
</nav>
<router-outlet>
