import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent implements OnChanges, AfterViewInit {
  @Input() value: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() disabled: boolean = false;
  @Input() fontsize: boolean = false;
  @Input() email: boolean = false;
  @Input() sellConditions: boolean = false;
  @ViewChild('content', { static: false }) content?: ElementRef<HTMLDivElement>;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value.currentValue == '') this.refreshView();
  }

  ngAfterViewInit(): void {
    this.refreshView();
  }

  public applyStyle(command: string, extra: string | null = null) {
    if (command == 'createLink') extra = prompt('Ingrese aquí el vínculo', 'https://')!.trim();
    document.execCommand(command, false, extra as string);
    if (command == 'createLink') {
      try {
        (document.getSelection() as any).anchorNode.parentElement['target'] = '_blank';
        document.getSelection()!.collapseToEnd();
      } catch (error) { }
    }
  }

  public increaseFontSize() {
    let fontSize = (document.getSelection() as any).anchorNode.parentElement.tagName == 'FONT' ?
      ((document.getSelection() as any).anchorNode.parentElement).size : '3';
    fontSize = (Number(fontSize) + 1).toString()
    if (Number(fontSize) > 7) fontSize = '7';
    this.applyStyle('fontSize', fontSize);
  }

  public decreaseFontSize() {
    let fontSize = (document.getSelection() as any).anchorNode.parentElement.tagName == 'FONT' ?
      ((document.getSelection() as any).anchorNode.parentElement as any).size : '3';
    fontSize = (Number(fontSize) - 1).toString()
    if (Number(fontSize) < 1) fontSize = '1';
    this.applyStyle('fontSize', fontSize);
  }

  public onUpdate() {
    const value = this.content!.nativeElement.innerHTML;
    this.valueChange.emit(value);
  }

  private refreshView() {
    if (!this.content) return;
    this.content.nativeElement.innerHTML = this.value || '';
  }
}
