import { Injectable } from '@angular/core';
import { DigitalQuotationMessagesResponse, DigitalQuotationResponse, QuotationMessage } from '../models/digital-quotation';
import { ApiResponse } from '../models/response';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  constructor(private api: ApiService) { }

  public async getDigitalQuotation(token: string) {
    const res = await this.api.get<DigitalQuotationResponse>('quotations/digitalQuotation/' + token);
    if (res.code != 200) { throw new Error(res.message); }
    return res.digitalQuotation;
  }

  public async getDigitalQuotationMessages(token: string) {
    const res = await this.api.get<DigitalQuotationMessagesResponse>('quotations/digitalQuotation/message?token=' + token);
    if (res.code != 200) { throw new Error(res.message); }
    return res.messages;
  }

  public async postDigitalQuotationMessage(token: string, text: string, files: File[]) {
    const formData = new FormData();
    files.forEach(file => formData.append('messageFiles', file));
    const message: QuotationMessage = { quotationId: 0, message: text, token };
    formData.append('message', JSON.stringify(message));
    const res = await this.api.postForm<ApiResponse>('quotations/digitalQuotation/message', formData);
    if (res.code != 200) { throw new Error(res.message); }
    return res.message;
  }
}
