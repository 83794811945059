<div class="text-editor" [ngClass]="{'edit-disabled': disabled}">
  <div class="text-editor-toolbar">
    <button class="btn btn-outline btn-sm" (click)="applyStyle('bold')" title="Negrita">
      <span class="material-icons">format_bold</span>
    </button>
    <button class="btn btn-outline btn-sm" (click)="applyStyle('italic')" title="Cursiva">
      <span class="material-icons">format_italic</span>
    </button>
    <button class="btn btn-outline btn-sm" (click)="applyStyle('underline')" title="Subrayado">
      <span class="material-icons">format_underlined</span>
    </button>
    <button class="btn btn-outline btn-sm" title="Color de letra" (click)="textColor.click()">
      <span class="material-icons text-danger">format_color_text</span>
      <input type="color" #textColor (input)="applyStyle('foreColor',textColor.value)">
    </button>
    <button class="btn btn-outline btn-sm" title="Color de fondo" (click)="backgroundColor.click()">
      <span class="material-icons">format_color_fill</span>
      <input type="color" #backgroundColor (input)="applyStyle('hiliteColor',backgroundColor.value)">
    </button>
    <button class="btn btn-outline btn-sm" (click)="applyStyle('removeFormat')" title="Limpiar formato">
      <span class="material-icons">format_clear</span>
    </button>
    <button class="btn btn-outline btn-sm" (click)="applyStyle('createLink')" title="Insertar vínculo">
      <span class="material-icons">link</span>
    </button>
    <button class="btn btn-outline btn-sm" (click)="applyStyle('insertUnorderedList')" title="Lista de puntos">
      <span class="material-icons">format_list_bulleted</span>
    </button>
    <button class="btn btn-outline btn-sm" (click)="applyStyle('insertOrderedList')" title="Lista numerada">
      <span class="material-icons">format_list_numbered</span>
    </button>
    <button class="btn btn-outline btn-sm" (click)="applyStyle('justifyLeft')" title="Alinear a la izquierda">
      <span class="material-icons">format_align_left</span>
    </button>
    <button class="btn btn-outline btn-sm" (click)="applyStyle('justifyCenter')" title="Alinear a la derecha">
      <span class="material-icons">format_align_center</span>
    </button>
    <button class="btn btn-outline btn-sm" (click)="applyStyle('justifyRight')" title="Centrar">
      <span class="material-icons">format_align_right</span>
    </button>
    <ng-container *ngIf="fontsize || email || sellConditions">
      <button class="btn btn-outline btn-sm" (click)="decreaseFontSize()" title="Aumentar tamaño de fuente">
        <span class="material-icons">text_decrease</span>
      </button>
      <button class="btn btn-outline btn-sm" (click)="increaseFontSize()" title="Disminuir tamaño de fuente">
        <span class="material-icons">text_increase</span>
      </button>
    </ng-container>
  </div>
  <div class="text-editor-content" [ngClass]="{'email-content': email, 'sell-conditions': sellConditions}"
    [attr.contenteditable]="!disabled" (blur)="onUpdate()" (input)="onUpdate()" #content>
  </div>
</div>