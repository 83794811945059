import { Component, Input, OnInit } from '@angular/core';
import { QuotationMessage } from 'src/app/models/digital-quotation';

@Component({
  selector: 'app-quotation-message',
  templateUrl: './quotation-message.component.html',
  styleUrls: ['./quotation-message.component.scss']
})
export class QuotationMessageComponent implements OnInit {

  @Input() message!: QuotationMessage;

  constructor() { }

  ngOnInit() { }

}
