import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Product } from '../models/products';
import { NavbarService } from '../services/navbar.service';
import { ProductService } from '../services/product.service';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {

  public product: Product = {} as Product;
  private subscription: Subscription = new Subscription();

  constructor(
    private productService: ProductService,
    private navbarService: NavbarService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('pid');
    const idIndustry= this.route.snapshot.paramMap.get('id');

    if (id) {
      this.loadProduct(+id);
    }
    this.navbarService.section = 'Industries';
    this.subscription = this.navbarService.navchange.subscribe(() => this.router.navigate(['/']));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async loadProduct(id: number) {
    this.product = await this.productService.getProduct(id);
    const { industryId } = this.product;
    this.navbarService.breadcrumbs = [
      { name: this.product.industryName, link: '/industry/' + industryId },
      { name: this.product.name, link: `/industry/${industryId}/product/${id}` }
    ]
  }

}
