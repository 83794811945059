import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FamilyProduct, Product, ProductFamily, ProductHeader, ProductPage } from '../models/products';
import { IndustryService } from '../services/industry.service';
import { NavbarService } from '../services/navbar.service';
import { ProductService } from '../services/product.service';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.scss']
})
export class IndustryComponent implements OnInit, OnDestroy {

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.keyScroll(event);
  }

  private subscription: Subscription = new Subscription();

  private canScroll: boolean = true;
  private currentSectionIndex: number = 0;
  private sections: string[] = ['intro', 'products'];

  public name: string = 'Cargando...';
  public videoUrl: string = '';
  public headers: ProductHeader[] = [];
  public selectedHeaderIndex: number = 0;
  public loading:boolean=true;
  public productPage:ProductPage[]=[]
  public productPage2:ProductPage[]=[]
  slideOffset: number = 0;
  public grupo:any[]=[]

  public products:Product[]=[]
  public hubConnection:HubConnection|any;
  constructor(
    private industryService: IndustryService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private navbarService: NavbarService,
  ) { }

  ngOnInit(): void {
    
    const main = document.getElementsByTagName('main')[0];
    main.addEventListener('wheel', (event) => this.mouseScroll(event));

    this.navbarService.section = 'Industries';
    this.subscription = this.navbarService.navchange.subscribe(() => this.router.navigate(['/']));

    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.loadIndustry(+id);
      this.loadProducts(+id);
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //#region Scroll related
  private mouseScroll(event: any) {
    event.preventDefault();
    const next = event.deltaY > 0 ? true : event.deltaY < 0 ? false : undefined;
    if (next === undefined) { return; }
    this.scrollSection(next);
  }

  public keyScroll(event: KeyboardEvent) {
    const { key } = event;
    if (['PageDown', 'PageUp', 'ArrowUp', 'ArrowDown'].includes(key)) {
      event.preventDefault();
      this.canScroll = true;
      this.scrollSection(['PageDown', 'ArrowDown'].includes(key));
    }
  }

  public scrollSection(next: boolean) {
    if (!this.canScroll) {
      return;
    }
    this.canScroll = false;
    setTimeout(() => { this.canScroll = true }, 1000);
    let scrollIndex = this.currentSectionIndex;
    scrollIndex += (next ? 1 : -1);
    const nextScroll = this.sections[scrollIndex];
    if (nextScroll) {
      this.scrollTo(nextScroll);
    }
  }

  public scrollTo(sectionName: string) {
    const section = document.getElementById('industry-' + sectionName) as HTMLDivElement;
    section.scrollIntoView();
    this.currentSectionIndex = this.sections.indexOf(sectionName);
  }
  //#endregion

  private async loadIndustry(id: number) {
    const industry = await this.industryService.getById(id);
    if (!industry.enabled) {
      this.router.navigate(['/']);
    }
    this.name = industry.name;
    this.videoUrl = industry.video?.url || '';
    this.navbarService.breadcrumbs = [
      { name: industry.name, link: 'industry/' + id }
    ];
  }

  private async loadProducts(industryId: number) {
    const headers = await this.productService.getHeaders(industryId);
    this.headers = headers.reverse();
    console.log(headers)
    headers.forEach(header => {
      header.familyProductsPage = [];
      header.familyProducts.forEach(f => f.products = f.products.filter(p => p.enabled));
      for (let i = 0; i < header.familyProducts.length; i += 2) {
        const chunk = header.familyProducts.slice(i, i + 2);
        if (chunk.length == 1) { // Se añade una familia vacía para mantener las 2 columnas
          chunk.push({ products: [] } as any);
        }
        header.familyProductsPage.push(chunk);
      }
    });
    

    for(var i=0;i<headers.length;i++){
        const headerName= headers[i].name
        headers[i].familyProducts.forEach(pp=>{        
          const products:Product[]=[]
        pp.products.forEach(p=>{
          products.push(p)        
        })
        const product:ProductPage={
          headerName:headerName,
          headerId:pp.headerId,
          name:pp.name,
          product:products
        }
        this.productPage.push(product)
       
        })
      
    }   
    
    this.productPage2=this.productPage.filter(x=>x.name!=='')
    
    this.agruparPorHeaderId(this.productPage)

   

    setTimeout(()=>{
      this.loading=false
    },2000)

  }
   agruparPorHeaderId  (array:ProductPage[])  {
   
    for (let i = 0; i < array.length; i ++) {
      
      var cantHeader=array.filter(x=>x.headerId===array[i].headerId).length
      if(cantHeader%2!==0){
        const product:ProductPage={
          headerName:array[i].headerName,
          headerId:array[i].headerId,
          name:'',
          product:[]
        }
        array.push(product)
      }

    }

    array=array.slice().sort((a, b) => a.headerId - b.headerId);
    // this.productPage=array
    
    const grouped:ProductFamily[] = [];
    for (let i = 0; i < array.length; i += 2) {
      
      if (array[i + 1]) {     
        var aux:ProductFamily={
          headerName:array[i].headerName,
          productos:[array[i], array[i + 1]]
        }   
        grouped.push(aux);
      } else {
        var aux:ProductFamily={
          headerName:array[i].headerName,
          productos:[array[i], {headerName:array[i].headerName,headerId:array[i].headerId,name:'',product:[]}]
        } 
        grouped.push(aux);
      }
    }
   
    
    this.grupo=grouped.reverse();
    this.grupo=this.grupo.slice().sort((a, b) => a.productos.length - b.productos.length);
     console.log('Grupos')
    console.log(this.grupo);
    this.productPage2=this.productPage.filter(x=>x.name!=='')
    
  };
 
 

  getProducs(index:number){
    const newArray=[...this.headers[index].familyProducts,[...this.headers[index].familyProductsPage]]
    console.log(newArray)
  }

  public changePage(next?: boolean) {
    if (next) {
      this.selectedHeaderIndex++;
      if (this.selectedHeaderIndex == this.headers.length) {
        this.selectedHeaderIndex = 0;
      }
    } else {
      this.selectedHeaderIndex--;
      if (this.selectedHeaderIndex < 0) {
        this.selectedHeaderIndex = this.headers.length - 1;
      }
    }
  }
  public currentIndex: number = 0;
  public currentIndexMobile: number = 0;
  showSlide(index: number) {
    const carousel = document.querySelector('.carousel') as HTMLDivElement;
    const slideWidth = carousel.clientWidth;
    carousel.style.transform = `translateX(-${slideWidth * index}px)`;
    this.currentIndex = index;
  }

  prevSlide() {
    if (this.currentIndex > 0) {
      this.showSlide(this.currentIndex - 1);
    }
    else{
      this.showSlide(this.grupo.length - 1)
    }
  }

  nextSlide() {
    if (this.currentIndex < this.grupo.length - 1) {
      this.showSlide(this.currentIndex + 1);
    }
    else{
      this.showSlide(0);
    }
  }
  showSlideMovile(index: number) {
    const carousel = document.querySelector('.carouselMobile') as HTMLDivElement;
    const slideWidth = carousel.clientWidth;
    carousel.style.transform = `translateX(-${slideWidth * index}px)`;
    this.currentIndexMobile = index;
  }

  prevSlideMobile() {
    console.log('mobile prev:',this.currentIndexMobile)
    if (this.currentIndexMobile > 0) {
      this.showSlideMovile(this.currentIndexMobile - 1);
    }
    else{
      this.showSlideMovile(this.productPage.length - 1)
    }
  }

  nextSlideMobile() {
    console.log('mobile next:',this.currentIndexMobile)
    if (this.currentIndexMobile < this.productPage.length - 1) {
      this.showSlideMovile(this.currentIndexMobile + 1);
    }
    else{
      this.showSlideMovile(0);
    }
  }
  pageSlide=0
  next=false
  pageProduc=1
  nextPage(){
    if(this.pageSlide>=this.headers.length){
      this.pageSlide=0
    }
    const product=(this.headers[this.pageSlide].familyProducts.length)
    if(!this.next){
      
      if(product===3){
        this.next=true
      }
      if(product<3){
        this.next=false;
      this.pageSlide++
      this.pageProduc=0
      this.changePage(true)
    }
    }
    else{
      this.next=false;
      this.pageSlide++
      this.pageProduc=0
      this.changePage(true)
    }
    console.log(this.pageSlide,this.headers.length)
    this.pageProduc ++
  }
  backPage(){
    this.next=false
    console.log(this.productPage)
    if(this.pageProduc===1 ||this.headers[this.pageSlide].familyProducts.length<=2){
      this.changePage()
      this.pageProduc=1
      if(this.pageSlide>0){
        this.pageSlide--
      }
      else{
        this.pageSlide=this.headers.length-1
      }
      if(this.headers[this.pageSlide].familyProducts.length>2){
        this.next=false
      }
    }
    else{
      this.pageProduc --

    }   
  }

  nextPageM(){
    if(this.pageSlide>=this.headers.length){
      this.pageSlide=0
    }
    const product=(this.headers[this.pageSlide].familyProducts.length)
    if(this.pageProduc===product){
      this.next=false;
      this.pageSlide++
      this.pageProduc=0
      this.changePage(true)
    }
    if(!this.next){
      
      if(product>=2){
        this.next=false
      }
      else{
        this.next=true;
      this.pageSlide++
      this.pageProduc=0
      this.changePage(true)
    }
    }
    else{
      this.next=false;
      this.pageSlide++
      this.pageProduc=0
      this.changePage(true)
    }
    console.log(this.pageSlide,this.headers.length)
    this.pageProduc ++
  }

  backPageM(){
    this.next=false
   
    if(this.pageProduc===1){
      this.changePage()
      this.pageProduc=1
      if(this.pageSlide>0){
        this.pageSlide--
      }
      else{
        this.pageSlide=this.headers.length-1
      }
      this.pageSlide--
      if(this.headers[this.pageSlide].familyProducts.length>2){
        this.next=false
      }
    }
    else{
      if(this.pageProduc!=0){

        this.pageProduc --
      }

    }   
  }
}
